<template>
  <span>
     <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          text class="primary--text"
          v-bind="attrs"
          v-on="on"
          @input="selectMaintenance"
        >
         <v-icon class="mr-1">settings</v-icon>
          Maintenance
        </v-btn>
      </template>
      <v-list>
        <v-list-item
          v-for="(item, index) in items"
          :key="index"
          @click="selectMaintenance(item)"
        >
          <v-list-item-title>{{ item.text }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-dialog v-if="device" v-model="dialog" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">{{this.selected.text}}</span>
        </v-card-title>
        <v-card-text>
          Are you sure want to  <strong>{{ this.selected.action }}</strong> edge device
          <strong>{{ device.name }}</strong
          >?
          <br/>

           <v-menu offset-y v-if="isLogLevelAction(this.selected.action)">
             <template v-slot:activator="{ on, attrs }">
               <v-btn
                 text class="primary--text"
                 v-bind="attrs"
                 v-on="on"
               >
                <v-icon class="mr-1">settings</v-icon>
                 LogLevel
               </v-btn>
             </template>
             <v-list>
               <v-list-item
                 v-for="(item, index) in logLevels"
                 :key="index"
                 @click="selectLogLevel(item)"
               >
                 <v-list-item-title>{{ item }}</v-list-item-title>
               </v-list-item>
             </v-list>
            </v-menu>
            {{ this.selectedLogLevel}}

          <p>
            This action might trigger ireversable behaviour.
            We will not be responsible if device will not come back online.
          </p>
        </v-card-text>
        <v-card-actions>
          <v-btn class="primary" text @click.native="dialog = false"
            >Cancel</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn class="primary--text" text @click.native="selected.func()"
            >{{this.selected.text}}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>

export default {
  data() {
    return {
      dialog: false,
      items:[
        {
          text: "Reboot Device",
          action: "restart",
          func: this.rebootDevice,
          id: 1,
        },{
          text: "Wipe out state",
          action: "wipe",
          func: this.wipeDevice,
          id: 2
        },
        {
          text: "Change log level",
          action: "LogLevel",
          func: this.changeLogLevel,
          id: 2
        }
      ],
      logLevels: [
       "info",
       "debug",
      ],
      selected: "",
      selectedLogLevel: "",
    }
  },
  props: {
    device: Object
  },

  computed: {
    error() {
      return this.$store.state.application.error
    },
    projectId() {
      return this.$route.params.project
    },
    selectedNamespaceId() {
      return this.$route.params.namespace
    },
    currentLogLevel(){
      return this.device.info.agentLogLevel
    }
  },

  methods: {
    rebootDevice() {
      this.$store.dispatch('RebootDevice', this.device).then(() => {
        if (this.error == null) {
          this.dialog = false
        }
      })
    },
    wipeDevice() {
      this.$store.dispatch('WipeDevice', this.device).then(() => {
        if (this.error == null) {
          this.dialog = false
        }
      })
    },
    changeLogLevel() {
      this.device.logLevel = this.selectedLogLevel
      this.$store.dispatch('UpdateDevice', this.device).then(() => {
        if (this.error == null) {
          this.dialog = false
        }
      })
    },
    selectMaintenance(event) {
      this.selected = event
      this.selectedLogLevel = this.currentLogLevel
      this.dialog=true
    },
    selectLogLevel(event) {
      this.selectedLogLevel = event
    },
    isLogLevelAction(action){
      return action == 'LogLevel'
    }
  }

}
</script>
